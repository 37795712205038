/*
 * @Author: ZHANGJIAN
 * @Date: 2021-10-22 14:27:38
 * @LastEditTime: 2021-12-14 15:32:06
 * @LastEditors: ZHANGJIAN
 * @Description:
 */

/**
 * @description: 提示枚举
 * @param {*}
 */
const Message = {
  error: "伺服器開小差了, 請刷新重試!",
};

/**
 * @description: 订单状态
 * @param {*}
 */
const ORDER_STATE = {
  success: "1",
  error: "2",
};
/**
 * @description:獲取用戶信息
 * @param {*}
 */

//获取用户信息
let getUserInfo = function () {
  var cuid = getCookie("CUID"),
    pic = getCookie("PIC");
  if (!cuid && !pic) {
    return null;
  }

  if (!cuid && /^\w+_(.*)$/.test(pic)) {
    cuid = RegExp["$1"];
  }
  if (!cuid) {
    return null;
  }
  var arr = cuid.split("-");
  arr[1] = unescape(arr[1]);
  return {
    uid: arr[0],
    passport: arr[1],
  };
};

//获取cookie信息
let getCookie = function (key) {
  if (!navigator.cookieEnabled) {
    return null;
  }

  if (typeof key === "string") {
    var reg = new RegExp("(?:; )?" + key + "=([^;]*);?");
    if (reg.test(document.cookie)) {
      return RegExp["$1"];
    }
  }

  return null;
};
//获取url参数
let getUrlParam = function (paraName) {
  var search = decodeURIComponent(document.location.search),
    reg = new RegExp("[?&]+" + paraName + "=([^&]+)");
  if (search && reg.test(search)) {
    return RegExp["$1"];
  }
  return null;
};
//获取url?后的參數
let getParam = function () {
  var search = decodeURIComponent(document.location.search);
  return search;
};

/**
 * 获取密码安全级别
 * @param {String} val
 * @return {Number} 安全级别
 *
 *		０－－－输入不符合验证要求
 * 		１－－－安全级别低	len>6 && 包含a-z
 * 		２－－－安全级别中	len>6 && 包含a-z|0-9
 * 		３－－－安全级别高	len>6 && 包含a-z|0-9|其它字符（如特殊字符!）
 */
let getPwdSecLevel = function (val) {
  var secLevel = 0;
  if (/[a-z]/i.test(val)) {
    secLevel++;
  }

  if (/\d+/.test(val)) {
    secLevel++;
  }

  if (/(.[^a-z0-9])/i.test(val) || val.length > 10) {
    secLevel++;
  }

  if (val.length < 6 && val.length > 0 && secLevel) {
    secLevel--;
  }

  return secLevel;
};

//获取浏览器语言
let getNavigatorLanguage = function () {
  return (
    navigator.language ||
    navigator.userLanguage ||
    navigator.browserLanguage ||
    navigator.systemLanguage
  );
};
//设置序列化参数
const setUrlParam = function (params) {
  const keys = Object.keys(params).sort((s1, s2) => (s1 > s2 ? 1 : -1));
  let str = "";
  for (let key of keys) {
    str += "&" + key + "=" + params[key];
  }
  str = str.slice(1);
  return str;
};

//倒計時
const timeDown = function (self, callback) {
  let _time = self.time;
  //重新發送
  if (self.time <= 0) {
    self.time = _time;
    console.log(callback);
    if (callback && typeof callback == "function") {
      callback && callback();
    }
  } else {
    self.time--;
    window.setTimeout(function () {
      timeDown(self, callback);
    }, 1000);
  }
};
/**
 * 节流,
 * @param {Function} fn  需要添加节流的方法
 * @param {Number} ms  防抖事件
 * @param {Object} that  当前对象
 * @returns {Function}
 */
const throttle = function (func, dalay = 300) {
  let prev = 0;
  return function () {
    let _this = this;
    let arms = arguments;
    let nowTime = Date.now();
    if (nowTime - prev > dalay) {
      prev = nowTime;
      func.apply(_this, arms);
    }
  };
};
//防抖
const debounce = function (func, delay) {
  let timer;
  return function () {
    if (timer) {
      clearTimeout(timer);
    }
    let _this = this;
    let arms = arguments;
    timer = setTimeout(function () {
      func.apply(_this, arms);
    }, delay);
  };
};
//补0
function add0(m) {
  return m < 10 ? "0" + m : m;
}
//时间戳转化成时间格式
const timeFormat = function (timestamp) {
  //timestamp是整数，否则要parseInt转换,不会出现少个0的情况
  timestamp = timestamp.replace(/\-/g, "/");
  var time = new Date(timestamp);
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  var hours = time.getHours();
  var minutes = time.getMinutes();
  var seconds = time.getSeconds();
  return (
    year +
    "年" +
    add0(month) +
    "月" +
    add0(date) +
    "日 " +
    add0(hours) +
    ":" +
    add0(minutes) +
    ":" +
    add0(seconds)
  );
};

//獲取今日時間
const dateFormat = function (date) {
  var time = (date && new Date(date)) || new Date();
  var year = time.getFullYear();
  var month = time.getMonth() + 1;
  var date = time.getDate();
  return year + "-" + add0(month) + "-" + add0(date);
};

/**
 * 复制
 * @returns {String}
 */
const copyTextToClipboard = (value, callback) => {
  var textArea = document.createElement("textarea");
  textArea.style.background = "transparent";
  textArea.value = value;
  document.body.appendChild(textArea);
  textArea.select();
  try {
    var successful = document.execCommand("copy");
  } catch (err) {
    console.log("Oops, unable to copy");
  }
  callback && typeof callback === "function" && callback();
  document.body.removeChild(textArea);
};
/**
 * @description: 判断是否空值
 * @param {*} a
 */
function isEmpty(a) {
  if (a === "") return true; //检验空字符串
  if (a === "null") return true; //检验字符串类型的null
  if (a === "undefined") return true; //检验字符串类型的 undefined
  if (!a && a !== 0 && a !== "") return true; //检验 undefined 和 null
  if (Array.prototype.isPrototypeOf(a) && a.length === 0) return true; //检验空数组
  if (Object.prototype.isPrototypeOf(a) && Object.keys(a).length === 0)
    return true; //检验空对象
  return false;
}

//检测登录
function checkLogin() {
  var userInfo = getUserInfo() || null;
  if (!userInfo) {
    $(".no_login").show();
    Store.SET_USERINFO(null);
    // Vuemethods.$message.warn("請先登入您的賬號！");
    setTimeout(function () {
      location.href =
        WEBSITE_HREF +
        `/login.html?returnUrl=${encodeURIComponent(
          location.origin + location.pathname
        )}`;
    }, 0);

    return false;
  } else {
    $(".is_login").show();
    $(".is_login .user_name").text(userInfo.passport);
    return true;
  }
}

//隱藏body滾動
var setOverflow = function (dom) {
  dom = dom || "body";

  $(dom).addClass("__overflow_hide");
};
var removeOverflow = function (dom) {
  dom = dom || "body";

  $(dom).removeClass("__overflow_hide");
};
